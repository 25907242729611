import { ReactNode, useState } from 'react';

import { VSpacingContainer } from '@hh.ru/magritte-ui';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import Link from 'src/components/MagritteRedesignComponents/Link';
import Text from 'src/components/MagritteRedesignComponents/Text';
import translation from 'src/components/translation';
import { useApplicantRenewedAuthExp } from 'src/hooks/useApplicantRenewedAuthExp';
import { useIsBelarus } from 'src/hooks/useCountries';
import { useSelector } from 'src/hooks/useSelector';

import styles from './styles.less';

const TrlKeys = {
    title: 'accountDeleteInactiveTime.title',
    description: 'accountDeleteInactiveTime.description',
    descriptionManual: 'accountDeleteInactiveTime.descriptionManual',
    descriptionShort: 'accountDeleteInactiveTime.description.with.link',
    descriptionManualShort: 'accountDeleteInactiveTime.descriptionManual.short',
    change: 'accountDeleteInactiveTime.change',
};

interface Props {
    renderSelect: () => ReactNode;
    spacingBefore?: ReactNode;
    spacingAfter?: ReactNode;
}

const Features = {
    enableAccountDeleteInactive: 'enable_account_delete_inactive',
};

const AccountDeleteInactive: TranslatedComponent<Props> = ({ trls, spacingBefore, spacingAfter, renderSelect }) => {
    const [isManual, setIsManual] = useState(false);
    const enableAccountDeleteInactive = useSelector(
        ({ features }) => features[Features.enableAccountDeleteInactive] as boolean
    );

    const isAnonymousMagritteExp = useSelector(({ isAnonymousMagritteExp }) => isAnonymousMagritteExp);
    const isBelarus = useIsBelarus();
    const { isApplicantRenewedAuthExp } = useApplicantRenewedAuthExp();

    if (!isBelarus || !enableAccountDeleteInactive) {
        return null;
    }

    const typography = isAnonymousMagritteExp ? 'paragraph-3-regular' : 'paragraph-2-regular';

    if (isApplicantRenewedAuthExp) {
        return isManual ? (
            <VSpacingContainer default={12}>
                {renderSelect()}
                <Text typography={typography} style="secondary">
                    {trls[TrlKeys.descriptionManualShort]}
                </Text>
            </VSpacingContainer>
        ) : (
            <Text typography={typography} style="secondary">
                {formatToReactComponent(trls[TrlKeys.descriptionShort], {
                    '{0}': (
                        <Link inline typography={typography} onClick={() => setIsManual(true)}>
                            {trls[TrlKeys.change]}
                        </Link>
                    ),
                })}
                <div className="g-hidden">{renderSelect()}</div>
            </Text>
        );
    }

    if (isAnonymousMagritteExp) {
        return isManual ? (
            <div className={styles.container}>
                {renderSelect()}
                <Text typography={typography} style="secondary">
                    {trls[TrlKeys.descriptionManualShort]}
                </Text>
            </div>
        ) : (
            <div>
                <Text typography={typography} style="secondary">
                    {formatToReactComponent(trls[TrlKeys.descriptionShort], {
                        '{0}': (
                            <Link inline typography={typography} onClick={() => setIsManual(true)}>
                                {trls[TrlKeys.change]}
                            </Link>
                        ),
                    })}
                </Text>
                <div className="g-hidden">{renderSelect()}</div>
            </div>
        );
    }

    return (
        <div>
            {spacingBefore}
            {isManual ? (
                <>
                    <Text typography="subtitle-3-semibold">{trls[TrlKeys.title]}</Text>
                    <VSpacing base={2} />
                    {renderSelect()}
                    <VSpacing base={2} />
                    <Text typography={typography} style="secondary">
                        {trls[TrlKeys.descriptionManual]}
                    </Text>
                </>
            ) : (
                <>
                    <Text typography={typography} style="secondary">
                        {trls[TrlKeys.description]}
                    </Text>
                    <VSpacing base={2} />
                    <Link typography={typography} onClick={() => setIsManual(true)}>
                        {trls[TrlKeys.change]}
                    </Link>
                    <div className="g-hidden">{renderSelect()}</div>
                </>
            )}
            {spacingAfter}
        </div>
    );
};

export default translation(AccountDeleteInactive);
