import { useEffect, useState } from 'react';

import { TranslatedComponent, translation } from '@hh.ru/front-static-app';
import { Alert, Button } from '@hh.ru/magritte-ui';
import { CheckCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';

const TrlKeys = {
    confirm: 'otpByCall.confirm',
    content: 'otpByCall.content',
    header: 'otpByCall.header',
};

interface OtpByCallProps {
    login?: string;
}

const OtpByCall: TranslatedComponent<OtpByCallProps> = ({ trls, login }) => {
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => setModalVisible(true), [setModalVisible]);

    return (
        <Alert
            icon={<CheckCircleFilledSize24 />}
            iconStyle="positive"
            layout="vertical"
            visible={modalVisible}
            onClose={() => setModalVisible(false)}
            title={trls[TrlKeys.header]}
            description={`${trls[TrlKeys.content]} ${login}`}
            buttons={
                <>
                    <Button mode="primary" style="accent" stretched onClick={() => setModalVisible(false)}>
                        {trls[TrlKeys.confirm]}
                    </Button>
                </>
            }
        />
    );
};

export default translation(OtpByCall);
