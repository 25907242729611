import Analytics from '@hh.ru/analytics-js';
import vrSurveyMrAuthFormSubmit from '@hh.ru/analytics-js-events/build/xhh/applicant/vr/survey/vr_survey_mr_auth_form_submit';
import vrSurveyMrRegFormSubmit from '@hh.ru/analytics-js-events/build/xhh/applicant/vr/survey/vr_survey_mr_reg_form_submit';
import vrSurveyStandardAuthFormSubmit from '@hh.ru/analytics-js-events/build/xhh/applicant/vr/survey/vr_survey_standard_auth_form_submit';
import vrSurveyStandardRegFormSubmit from '@hh.ru/analytics-js-events/build/xhh/applicant/vr/survey/vr_survey_standard_reg_form_submit';
import { urlParser } from '@hh.ru/browser-api-utils';

import { VrSurveyType } from 'src/models/vrSignupLogin';

const getSafeStringQueryParam = (allQueryParams: Record<string, unknown>, key: string) => {
    const value = allQueryParams[key];
    if (Array.isArray(value)) {
        return String(value[0]);
    } else if (typeof value === 'string') {
        return value;
    }
    return '';
};

const getAnalyticsCommonParams = (analyticsParams: Record<string, string>, allQueryParams: Record<string, unknown>) => {
    const profRoles = allQueryParams?.profRoles;
    return {
        utmSource: getSafeStringQueryParam(allQueryParams, 'utm_source'),
        utmCampaign: getSafeStringQueryParam(allQueryParams, 'utm_campaign'),
        utmContent: getSafeStringQueryParam(allQueryParams, 'utm_content'),
        utmMedium: getSafeStringQueryParam(allQueryParams, 'utm_medium'),
        utmTerm: getSafeStringQueryParam(allQueryParams, 'utm_term'),
        vrResponseHash: getSafeStringQueryParam(allQueryParams, 'vrResponseHash'),
        profRoleId: Array.isArray(profRoles) ? profRoles.join(',') : '',
        hhtmSource: analyticsParams?.hhtmSource,
    };
};

const isWithSuitableVacanciesSurvey = (surveyType?: VrSurveyType): boolean => {
    return surveyType === 'WITH_SUITABLE_VACANCIES';
};

const geAllQueryParams = (vrSignupLoginPageQuery: string): Record<string, unknown> => {
    try {
        const { params } = urlParser(`?${vrSignupLoginPageQuery}`);
        return params;
    } catch (_) {
        return {};
    }
};

const toVrSignupLogin = (vrSignupLoginPageQuery: string): void => {
    window.location.assign(`/vr_signup_login?${vrSignupLoginPageQuery}`);
};

export const handleVrSuccessCreateUser = (
    surveyType: VrSurveyType,
    vrSignupLoginPageQuery: string,
    analyticsParams: Record<string, string>
): void => {
    const queryParams = geAllQueryParams(vrSignupLoginPageQuery);
    const analyticsCommonParams = getAnalyticsCommonParams(analyticsParams, queryParams);

    if (isWithSuitableVacanciesSurvey(surveyType)) {
        vrSurveyMrRegFormSubmit(analyticsCommonParams);
        // удалить аналитику ниже, когда закончится эксп https://jira.hh.ru/browse/PORTFOLIO-38674
        Analytics.sendHHEvent('form_submit', {
            formName: 'multiresponse_registration',
            ...queryParams,
            ...analyticsParams,
        });
        Analytics.sendEvent('applicant', 'register_multiresponse');
    } else {
        vrSurveyStandardRegFormSubmit(analyticsCommonParams);
    }

    toVrSignupLogin(vrSignupLoginPageQuery);
};

export const handleVrSuccessAuthorizationUser = (
    surveyType: VrSurveyType,
    vrSignupLoginPageQuery: string,
    analyticsParams: Record<string, string>
): void => {
    const queryParams = geAllQueryParams(vrSignupLoginPageQuery);
    const analyticsCommonParams = getAnalyticsCommonParams(analyticsParams, queryParams);

    if (isWithSuitableVacanciesSurvey(surveyType)) {
        vrSurveyMrAuthFormSubmit(analyticsCommonParams);
        // удалить аналитику ниже, когда закончится эксп https://jira.hh.ru/browse/PORTFOLIO-38674
        Analytics.sendHHEvent('form_submit', {
            formName: 'multiresponse_authorization',
            ...queryParams,
            ...analyticsParams,
        });
        Analytics.sendEvent('applicant', 'auth-login-success_multiresponse');
    } else {
        vrSurveyStandardAuthFormSubmit(analyticsCommonParams);
    }

    toVrSignupLogin(vrSignupLoginPageQuery);
};
